import _defineProperty from "/builds/u5yGSXwH/8/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCallback, useMemo } from 'react';
import qs from 'qs';
import { useSessionStorage, sessionStorage } from 'react-storage';
import { productDetailRoute } from '@src/routes';
import { CatalogType } from '@src/types/graphql-types';
import { SearchFocusType } from '@src/utils/searchUtils';
import { sendProductInfoInteractionEvent } from '@src/utils/analytics';
import { GTMEventCategory, EventValues } from '@sial/common-utils';
export var PROUCT_COMPARE_DELIMITERS = {
  PRODUCTS: ',',
  OPTION_SETS: ':'
};
export var parseProductCompareString = function parseProductCompareString(productsUrlString) {
  if (!productsUrlString || productsUrlString.length === 0) {
    return [];
  }

  var productSet = productsUrlString.split(PROUCT_COMPARE_DELIMITERS.PRODUCTS);

  if (!productSet || productSet.length === 0) {
    return [];
  }

  var productsList = [];
  productSet.map(function (product) {
    var options = product.split(PROUCT_COMPARE_DELIMITERS.OPTION_SETS);

    if (options && options.length >= 1) {
      var productKey = options[0];
      var brandKey = options.length > 1 ? options[1] : '';
      var catalogType = options.length > 2 ? options[2] : '';
      var productDetais = {
        productKey: productKey,
        brandKey: brandKey
      };

      if (catalogType) {
        if (catalogType === CatalogType.B2b) {
          productDetais.catalogType = CatalogType.B2b;
        } else if (catalogType === CatalogType.Buildingblocks) {
          productDetais.catalogType = CatalogType.Buildingblocks;
        } else if (catalogType === CatalogType.Sial) {
          productDetais.catalogType = CatalogType.Sial;
        }
      }

      productsList.push(productDetais);
    }

    return product;
  });
  return productsList;
};
export var buildProductCompareString = function buildProductCompareString(compareProducts) {
  return compareProducts.map(function (product) {
    var productKey = product.productKey;
    var brandKey = product.brandKey;
    var catalogType = product.catalogType;
    return "".concat(productKey).concat(PROUCT_COMPARE_DELIMITERS.OPTION_SETS).concat(brandKey).concat(PROUCT_COMPARE_DELIMITERS.OPTION_SETS).concat(catalogType);
  }).join(PROUCT_COMPARE_DELIMITERS.PRODUCTS);
};
var COMPARE_PRODUCTS_STORAGE_KEY = 'compareProducts';
export var MAX_COMPARE_SIZE = 4;
export function useCompareProducts() {
  var compareProducts = useSessionStorage(COMPARE_PRODUCTS_STORAGE_KEY, []);
  var addCompareProduct = useCallback(function (product) {
    if (!Array.isArray(compareProducts)) {
      return sessionStorage.setItem(COMPARE_PRODUCTS_STORAGE_KEY, [product]);
    }

    if (compareProducts.length >= MAX_COMPARE_SIZE) {
      return;
    }

    var productAlreadyAdded = compareProducts.findIndex(function (_ref) {
      var brandKey = _ref.brandKey,
          productKey = _ref.productKey;
      return product.brandKey === brandKey && product.productKey === productKey;
    }) >= 0;

    if (!productAlreadyAdded) {
      return sessionStorage.setItem(COMPARE_PRODUCTS_STORAGE_KEY, compareProducts.concat([product]));
    }
  }, [compareProducts]);
  var removeCompareProduct = useCallback(function (product) {
    if (!Array.isArray(compareProducts)) {
      return;
    }

    var productIndex = compareProducts.findIndex(function (_ref2) {
      var brandKey = _ref2.brandKey,
          productKey = _ref2.productKey;
      return product.brandKey === brandKey && product.productKey === productKey;
    });

    if (productIndex >= 0) {
      return sessionStorage.setItem(COMPARE_PRODUCTS_STORAGE_KEY, compareProducts.slice(0, productIndex).concat(compareProducts.slice(productIndex + 1)));
    }
  }, [compareProducts]);
  var clearAllCompareProducts = useCallback(function () {
    var newValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return sessionStorage.setItem(COMPARE_PRODUCTS_STORAGE_KEY, newValue);
  }, []);
  var compareProductsMemo = useMemo(function () {
    return Array.isArray(compareProducts) ? compareProducts : [];
  }, [compareProducts]);
  var sendToProductCompareDetail = useCallback(function (query, router) {
    var isStructureSearch = query.focus === SearchFocusType.StructureSearch;
    sendProductInfoInteractionEvent({
      action: EventValues.CompareProducts,
      detail: compareProducts === null || compareProducts === void 0 ? void 0 : compareProducts.map(function (product) {
        return product.productKey;
      }).join('|'),
      section: 'choose up to 4 products to compare',
      component: 'slider',
      elementType: 'button',
      elementText: 'compare'
    }, {
      eventCategory: isStructureSearch ? GTMEventCategory.StructureSearchResultPage : GTMEventCategory.SearchResultPage,
      eventAction: EventValues.CompareProducts,
      eventLabel: compareProducts === null || compareProducts === void 0 ? void 0 : compareProducts.map(function (product) {
        return product.productKey;
      }).join(' - '),
      eventInteractionType: 0
    });
    var productCompareString = buildProductCompareString(compareProducts || []);

    var compareQuery = _objectSpread(_objectSpread({}, query), {}, {
      products: productCompareString
    });

    return router.push("".concat(productDetailRoute.compare(), "?").concat(qs.stringify(compareQuery)));
  }, [compareProducts]);
  return {
    compareProducts: compareProductsMemo,
    addCompareProduct: addCompareProduct,
    removeCompareProduct: removeCompareProduct,
    clearAllCompareProducts: clearAllCompareProducts,
    sendToProductCompareDetail: sendToProductCompareDetail
  };
}