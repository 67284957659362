import _defineProperty from "/builds/u5yGSXwH/8/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import { makeStyles } from '@material-ui/core/styles';
export var useWalkMeAlertStyles = makeStyles(function (theme) {
  return {
    alertHeading: {
      fontWeight: theme.typography.fontWeightMedium
    },
    alertDescription: _defineProperty({
      marginTop: theme.spacing(2)
    }, theme.breakpoints.down('sm'), {
      lineHeight: 1.2
    }),
    alertActions: _defineProperty({}, theme.breakpoints.up('md'), {
      display: 'flex',
      justifyContent: 'space-between'
    }),
    link: {
      display: 'block',
      marginTop: theme.spacing(4)
    },
    largeLink: {
      fontSize: theme.typography.pxToRem(16)
    },
    alertBottomMargin: {
      marginBottom: theme.spacing(6)
    }
  };
});