import _defineProperty from "/builds/u5yGSXwH/8/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _searchSortOptions;

import { SearchFocusType } from '@src/utils/searchUtils';
import { Sort } from '@src/types/graphql-types';
import { messages } from './messages';
var relevanceOnly = [{
  value: Sort.Relevance,
  label: messages.SORT_BY_RELEVANCE
}];
var productSortOptions = [{
  value: Sort.Relevance,
  label: messages.SORT_BY_RELEVANCE
}, {
  value: Sort.Nameasc,
  label: messages.SORT_BY_NAME_ASCENDING
}, {
  value: Sort.Namedesc,
  label: messages.SORT_BY_NAME_DESCENDING
}];
var buildingBlocksStructureSearchSortOptions = [{
  value: Sort.Relevance,
  label: messages.SORT_BY_RELEVANCE
}, {
  value: Sort.Molwtasc,
  label: messages.SORT_BY_MOL_WT_ASC
}, {
  value: Sort.Molwtdesc,
  label: messages.SORT_BY_MOL_WT_DESC
}];
var geneSortOptions = [{
  value: Sort.Relevance,
  label: messages.SORT_BY_RELEVANCE
}, {
  value: Sort.Genenameasc,
  label: messages.SORT_BY_NAME_ASCENDING
}, {
  value: Sort.Genenamedesc,
  label: messages.SORT_BY_NAME_DESCENDING
}];
export var searchSortOptions = (_searchSortOptions = {}, _defineProperty(_searchSortOptions, SearchFocusType.Products, productSortOptions), _defineProperty(_searchSortOptions, SearchFocusType.Genes, geneSortOptions), _defineProperty(_searchSortOptions, SearchFocusType.Papers, relevanceOnly), _defineProperty(_searchSortOptions, SearchFocusType.TechnicalDocuments, relevanceOnly), _defineProperty(_searchSortOptions, SearchFocusType.SiteContent, relevanceOnly), _defineProperty(_searchSortOptions, SearchFocusType.BuildingBlocks, buildingBlocksStructureSearchSortOptions), _defineProperty(_searchSortOptions, SearchFocusType.StructureSearch, buildingBlocksStructureSearchSortOptions), _defineProperty(_searchSortOptions, SearchFocusType.B2B, buildingBlocksStructureSearchSortOptions), _defineProperty(_searchSortOptions, SearchFocusType.Chromatograms, productSortOptions), _searchSortOptions);